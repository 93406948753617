.header-landing {
    margin-top: 40px;
    height: auto;
    display: flex;
    @media screen and (max-width: 547px) {
        flex-wrap: wrap-reverse;
    }
    justify-content: space-around;

    .header-description-container {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        row-gap: 16px;
        @media screen and (max-width: 904px) {
            width: 100%;
        }
        width: 50%;

        .header-title {
            color: #1F1E23;
            font-family: Rammetto One;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: uppercase;
            margin: 0;
        }
    
        .header-description {
            color: #1F1E23;
            font-family: Lato;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 8px;
        }
    }

    .header-image {
        @media screen and (max-width: 904px) {
            width: 100%;
        }
        width: 50%;
        object-fit: contain;
    }
}