.login-container {
    width: 74%;
    margin: 13%;
    justify-self: center;
    display: grid;
    row-gap: 12px;
    padding: 16px;

    border-radius: 16px;
    background: #F5EBDC;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);

    .login-title {
        color: #1F1E23;
        font-family: Rammetto One;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;

        margin: 0;
    }

    .login-description {
        color: #1F1E23;
        font-family: Lato;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .login-new-password-link {
        color: var(--colores-negro, #1F1E23);
        font-family: Lato;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-decoration-line: underline;
        padding: 0;
        border: none;
        background: none;
        display: flex;
        cursor: pointer;
    }
}