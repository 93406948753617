.modal-container {
    display: flex;
    width: 415px;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    border-radius: 16px;
    background: #F5EBDC;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);

    top: 200px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999999;
}

.modal-title {
    color: #1F1E23;
    font-family: Rammetto One;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    margin: 0;
}

.modal-description {
    color: #1F1E23;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}