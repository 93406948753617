.vision-section {
    display: grid;
    row-gap: 15px;

    .vision-title {
        color: #1F1E23;
        font-family: Rammetto One;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        margin: 0;
        margin-top: 30px;
    }

    .vision-description {
        color: #1F1E23;
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}