.product-container {
    border-radius: 16px;
    width: min-content;
    margin: 0;

    .product-description-container {
        display: flex;
        margin: auto;
        padding: 16px;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        border-radius: 16px 16px 0px 0px;
        background: #F5EBDC;
        height: 90px;

        .product-title {
            color: #1F1E23;
            text-align: center;
            font-family: Rammetto One;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: uppercase;
            margin: 0;
            margin-bottom: 6px;
        }

        .product-description {
            color: #1F1E23;
            font-family: Lato;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin: 0;
            align-self: stretch;
            height: auto;
        }

    }

    .product-image {
        border-radius: 0px 0px 16px 16px;
        @media screen and (max-width: 547px) {
            width: 350px;
        }
        @media screen and (min-width: 1200px) {
            width: 350px;
        }
    }
}

