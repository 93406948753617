.products-section {
    padding: 24px 16px;
    
    .products-title {
        color: #1F1E23;
        font-family: Rammetto One;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        margin: 0;
    }

    .products-text {
        color: #1F1E23;
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .products-container {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        justify-content: space-evenly;
        row-gap: 16px;
    }
}