.data-input-container {
    display: flex;
    align-items: center;
    height: 59px;
    gap: 16px;
    align-self: stretch;

    .input-container {
        width: 65%;
        display: grid;
        align-items: center;
    }

    hr { 
        border:         none;
        border-left:    1px solid hsla(200, 10%, 50%,100);
        height:         100%;
        width:          1px;       
    }

    .data-input-description {
        width: 30%;
    }
}