nav {
    width: 100vw;
    display: grid;
    row-gap: 5%;
    position: fixed;
    left: 0%;

    .navbar-container {
        height: 43px;
        width: 95vw;
        margin-left: calc((100% - 95vw) / 2);
        border-radius: 16px;
        background: #F5EBDC;

        .navbar-inside {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            padding: 0px 3%;
        }

        .navbar-links-container {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            column-gap: 5%;
            width: 50%;

        }
    }

    .navbar-menu {
        position: relative;
        z-index: 9;
        margin: 0;
        display: flex;
        padding: 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        z-index: 99;

        margin-left: calc((100% - 95vw) / 2);
        margin-right: calc((100% - 95vw) / 2);

        border-radius: 16px;
        background: var(--colores-neutro-oscuro, #F5EBDC);
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
    }

    .navbar-link {
        cursor: pointer;
        color: var(--colores-primario, #FF8E27);
        text-align: center;
        font-family: Lato;
        font-size: 14px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        text-decoration: none;
    }
}