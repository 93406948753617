.admin-container {
    margin-top: 4%;
    display: grid;
    row-gap: 16px;

    .admin-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;

        .admin-title {
            color: #1F1E23;
            font-family: Rammetto One;
            font-size: 22px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: uppercase;
        }

        .admin-logout {
            color: var(--colores-acento, #B04324);
            text-align: center;
            font-family: Lato;
            font-size: 16px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
            text-decoration: none;
        }
    }
    
    .settings-container {
        display: flex;
        padding: 16px;
        flex-direction: column;
        gap: 32px;
    
        border-radius: 16px;
        background: var(--colores-neutro-oscuro, #F5EBDC);
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
    
        .settings-header {
            align-items: center;
            display: flex;
            height: 40px;
            justify-content: space-between;
    
            .settings-title {
                color: #1F1E23;
                font-family: Rammetto One;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: uppercase;
            }
        }    
    }
}
