.brands-section {
    padding: 24px 16px;
    gap: 16px;

    .brands-title {
        margin: 0;
        margin-bottom: 16px;
        color: #1F1E23;
        font-family: Rammetto One;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
    }

    .brands-container {
        display: flex;
        margin: 0;
        flex-wrap: wrap;
        gap: 16px;
        justify-content: center;
        align-items: center;

        img {
            width: 140px;
        }
    }
}