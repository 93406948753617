.not-found-container {
    width: 74%;
    margin: 13%;
    justify-self: center;
    display: grid;
    row-gap: 12px;
    padding: 16px;

    border-radius: 16px;
    background: #F5EBDC;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);


    .not-found-title {
        color: #1F1E23;
        font-family: Rammetto One;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        margin: 0;
    }

    .not-found-description {
        color: #1F1E23;
        font-family: Lato;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 2%;
    }
}