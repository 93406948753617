.service-container {
    display: flex;
    width: 320px;
    padding: 8px;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    .service-title {
        color: #1F1E23;
        text-align: center;
        font-feature-settings: 'liga' off;
        font-family: Rammetto One;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        margin: 0;
    }

    .service-description {
        color: #1F1E23;
        text-align: center;
        font-feature-settings: 'liga' off;
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0;
    }

    .service-image {
        width: min-content;
    }
}