.contact-section {
    margin: auto;
    display: flex;
    min-height: 218px;
    padding: 24px 16px;
    justify-content: center;
    gap: 12px;
    @media screen and (max-width: 547px) {
        flex-wrap: wrap;
    }

    .contact-title {
        margin-top: 0px;
        margin-bottom: 8px;
        color: #1F1E23;
        font-family: Rammetto One;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
    }

    border-radius: 16px;
    background: #F5EBDC;

    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);

    .contact-text-container {
        width: 100%;
        margin: auto;
        margin-top: 0px;
    }

    .text {
        color: #1F1E23;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .contact-form {
        display: grid;
        row-gap: 8px;
        width: 100%;
    }
}