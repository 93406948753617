@import url('https://fonts.googleapis.com/css2?family=Lato&family=Rammetto+One&display=swap');

body {
  margin: 0 !important;
  font-family: 'Lato', sans-serif,'Rammetto One', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
