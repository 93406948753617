footer {
    margin-top: 16px;
    display: grid;
    align-items: center;
    @media screen and (max-width: 547px) {
        height: 135px;
    }
    height: 105px;
    padding: 16px;
    row-gap: 16px;
    flex-wrap: wrap;

    border-radius: 16px;
    background: #1F1E23;

    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);

    figure {
        display:  flex;
        margin: 0;
        width: inherit;
        justify-content: space-between;

        .icon-container {
            display: flex;
            cursor: pointer;
        }
    }

    i {
        color: var(--neutral-300, #D1D5DB);
        font-feature-settings: 'liga' off;
        font-family: Lato;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }

    .link-container {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        row-gap: 10px;

        .link {
            color: var(--neutral-300, #D1D5DB);
            font-weight: 700;
            margin-left: 8px;
            margin-right: 8px;
            cursor: pointer;
            text-decoration: none;
        }
    }
}
