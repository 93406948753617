.new-password-container {
    width: 74%;
    margin: 13%;
    justify-self: center;
    display: grid;
    row-gap: 12px;
    padding: 16px;

    border-radius: 16px;
    background: #F5EBDC;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);

    .new-password-title {
        color: #1F1E23;
        font-family: Rammetto One;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;

        margin: 0;
    }

    .new-password-description {
        color: #1F1E23;
        font-family: Lato;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .new-password-suggestion {
        margin: 0;
        color: var(--colores-negro, #1F1E23);
        font-family: Lato;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration: none;
        margin-top: -5px;
    }
}