.whatsapp-button {
    display: flex;
    width: 80px;
    height: 80px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    position: fixed;
    bottom: 1%;
    @media (max-width: 1239px) {
        left: 85%;
      }

    @media (max-width: 816px) {
        left: 73%;
      }  
    left: 75%;
    border: none;
    border-radius: 200px;
    background: #26D366;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.20);

    cursor: pointer;
}

.whatsapp-button:active {
    background: #26AA66;
}

.whatsapp-button:hover {
  background: #28DA6A;
}