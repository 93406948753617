.list-of-products-section {
    margin-top: 45px;
    background: #1F1E23;
    width: 100vw;
    margin-left: calc((100% - 100vw) / 2);
    padding: 16px 0px;
    row-gap: 13px;
    height: auto;

    .list-of-products-container {
        display: flex;
        row-gap: 8px;
        height: auto;
        flex-wrap: wrap;
        flex-direction: column;
        padding: 0px 20%;
        @media (max-width: 1239px) {
            padding: 0px 5%;
          }

        .list-of-products-title {
            color: #FFF;
            font-family: Rammetto One;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: uppercase;
            margin: 0;
        }
    
        .list-of-products-description {
            color: #FFF;
            font-family: Lato;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin: 0;
            margin-bottom: 5px;
        }
    
        .list-of-products-button {
            margin-top: 16px;
        }
    }
}