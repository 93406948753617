.App {
  @media (max-width: 1239px) {
    padding: 16px 5% 108px 5%;
  }
  padding: 16px 20% 108px 20%;
  overflow-x: hidden;
  background-color: #FFF;
}

.overlay-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.navbar-modal-container {
  position: fixed;
  top: 2%;
  left: 0;
  transform: translate(-50%, -50%);
  z-index: 999999;
}