.input-container {
    display: grid;
    row-gap: 5px;

    .input {
        display: flex;
        position: relative;
    }

    .input-title {
        color: var(--colores-negro, #1F1E23);
        font-family: Lato;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .input-text-validate {
        color: #EA4335;
        font-family: Lato;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-decoration: none;
    }

    .icon-input {
        position: absolute;
        right: 21px;
        top: 9px;
        cursor: pointer;
    }

    input {
        width: 100%;
        border-radius: 8px;
        border: 1px solid var(--colores-primario, #FF8E27);
        background: var(--colores-neutro-claro, #FAF7F2);
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
        display: flex;
        padding: 10px;
        align-items: center;
        gap: 10px;
        align-self: stretch;
    
        color: rgba(31, 30, 35, 0.65);
        font-family: Lato;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}