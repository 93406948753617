.primary-button {
    display: flex;
    padding: 8px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;

    cursor: pointer;

    border: none;
    border-radius: 8px;
    background: var(--colores-primario, #FF8E27);
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);

    color: #FFF;
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

.primary-button:disabled {
    opacity: 0.5;
    background: var(--colores-primario, #FF8E27) !important;
}

.primary-button:hover {
    background: #FF7A00;
}

.primary-button:active {
    background: #E58023;
}